<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VInputCode from "@magnit/core/src/components/VInputCode/VInputCode.vue";
import VYaCaptcha from "@magnit/core/src/containers/VYaCaptcha/VYaCaptcha.vue";
import VFieldControl from "~/components/VFieldControl.vue";
import useCountdown, {
  formatCountdownToMMSS,
} from "~/composables/useCountdown";
import { useOTPStore } from "~/store/otp";

const INVISIBLE_CAPTCHA_ENABLED = false;

const config = useRuntimeConfig();
const { send } = AnalyticManager;
const otpStore = useOTPStore();
const countdown = useCountdown(otpStore.state.expire);

const otp = ref("");
const yaCaptcha = ref<null | string>(null);
const yaCaptchaRef = ref<null | InstanceType<typeof VYaCaptcha>>(null);
const inputOTPRef = ref<null | InstanceType<typeof VInputCode>>(null);
const isSubmitDisabled = computed(
  () =>
    otpStore.status.auth === "pending" ||
    countdown.status.value === "ticking" ||
    !yaCaptcha.value,
);
const submitBtnText = computed(() =>
  countdown.status.value === "expired"
    ? "Отправить код повторно"
    : `Повторить запрос через ${formatCountdownToMMSS(countdown.value.value)}`,
);

const onYaCaptchaTokenChange = (token?: string) => {
  if (token) {
    yaCaptcha.value = token;
  }
};

const onSubmit = () => {
  if (countdown.status.value === "expired") {
    send("Auth:PhoneConfirm:ReSubmit:Click");
  }

  otpStore.getCode(yaCaptcha.value);
  yaCaptcha.value = null;
  yaCaptchaRef.value?.reset();
  yaCaptchaRef.value?.execute();
};

watch(otp, otpStore.checkCode);

watch(
  () => otpStore.status.auth,
  (nextStatus) => {
    if (nextStatus === "error") {
      setTimeout(() => {
        inputOTPRef.value?.clear();
        inputOTPRef.value?.focus();
      }, 3000);
    }
  },
);

watch(
  () => otpStore.state.expire,
  (nextExpire) => {
    if (nextExpire) {
      countdown.update(nextExpire);
    }
  },
);

onMounted(() => {
  if ("OTPCredential" in window && navigator?.credentials) {
    navigator.credentials
      .get({
        // @ts-ignore
        otp: { transport: ["sms"] },
      })
      .then((v) => {
        // @ts-ignore
        otp.value = v?.code || "";
      });
  }
});
</script>

<template>
  <div>
    <VFieldControl
      class="otp-step__control"
      :has-error="otpStore.status.auth === 'error'"
      :error-text="
        otpStore.status.otpError === 'invalidOTPCode'
          ? 'Неправильный код доступа'
          : 'Ошибка. Попробуйте ещё раз'
      "
    >
      <div class="otp-step__input">
        <VInputCode
          ref="inputOTPRef"
          v-model="otp"
          :has-error="otpStore.status.auth === 'error'"
          :success="otpStore.status.auth === 'success'"
          :disabled="otpStore.status.auth === `pending`"
        />
      </div>
    </VFieldControl>

    <VButton
      full-width
      :disabled="isSubmitDisabled"
      :loading="
        otpStore.status.auth === `pending`
          || countdown.status.value === `initial`
      "
      @click="onSubmit"
    >
      {{ submitBtnText }}
    </VButton>

    <VYaCaptcha
      v-show="
        otpStore.status.auth === `success`
          || countdown.status.value === `expired`
      "
      ref="yaCaptchaRef"
      class="otp-step__captcha"
      :invisible="INVISIBLE_CAPTCHA_ENABLED"
      :hide-shield="INVISIBLE_CAPTCHA_ENABLED"
      :no-auto-execute="INVISIBLE_CAPTCHA_ENABLED"
      :captcha-key="config.public.yandexCaptcha"
      @token-changed="onYaCaptchaTokenChange"
    />
  </div>
</template>

<style lang="postcss">
.otp-step__control {
  margin-bottom: 16px;
}

.otp-step__input {
  display: flex;
  justify-content: center;
}

.otp-step__captcha {
  margin: 16px 0 0;
}
</style>
