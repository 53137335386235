<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import ArrowLeftIcon from "@magnit/icons/src/assets/icons/arrow-left-corner-24.svg";
import VOTPStep from "~/containers/Modals/VLoginModal/VOTPStep.vue";
import VPhoneStep from "~/containers/Modals/VLoginModal/VPhoneStep.vue";
import { useOTPStore } from "~/store/otp";
import { useAuthStore } from "~/store/auth";

const emit = defineEmits<{
  close: [];
}>();

const { send } = AnalyticManager;

const authStore = useAuthStore();
const otpStore = useOTPStore();

const step = ref<"phone" | "otp">("phone");

const {
  isActive,
  isOpen,
  close: closeLoginModal,
  name: modalName,
} = useLoginModal();

const title = computed(() => {
  return step.value === "phone" ? "Вход и регистрация" : "Введите код из СМС";
});

const subtitle = computed(() => {
  return step.value === "phone"
    ? "Нужен только номер телефона"
    : `Отправлен на номер ${otpStore.state.phone.replace(/ /g, "\xA0")}`;
});

const onBackward = () => {
  send("Auth:PhoneConfirm:Back:Click");
  step.value = "phone";
  otpStore.status.code = "initial";
  otpStore.status.auth = "initial";
};

const onClose = () => {
  if (step.value === "phone") send("Auth:PhoneNumber:Close:Click");
  if (step.value === "otp") send("Auth:PhoneConfirm:Close:Click");
  step.value = "phone";
  otpStore.state.phone = "";
  otpStore.state.code = "";
  otpStore.status.code = "initial";
  otpStore.status.auth = "initial";

  closeLoginModal();
  emit("close");
};

watch(
  () => [otpStore.status.code, otpStore.status.auth],
  ([statusCode, statusAuth]) => {
    if (statusCode === "success") {
      step.value = "otp";
    }

    if (statusAuth === "success") {
      onClose();
    }
  },
);

watch(
  () => authStore.status.login,
  (authStatus) => {
    if (authStatus === "success") {
      onClose();
    }
  },
);

watch(step, (v) => {
  if (v === "otp") send("Auth:PhoneConfirm:View");
  if (v === "phone") send("Auth:PhoneNumber:View");
});

watch(isOpen, (v) => {
  if (!v) return;
  send("Auth:PhoneNumber:View");
});
</script>

<template>
  <VModal
    :id="modalName"
    :active="isActive"
    :open="isOpen"
    :title="title"
    :subtitle="subtitle"
    type="bottom-sheet"
    @header-left="onBackward"
    @close="onClose"
  >
    <template #headerLeft>
      <VHidden :hide="step === 'phone'">
        <VIcon>
          <ArrowLeftIcon />
        </VIcon>
      </VHidden>
    </template>

    <VPhoneStep v-if="step === 'phone'" />

    <VOTPStep v-else />
  </VModal>
</template>
