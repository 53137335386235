<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import type { Swiper as SwiperClass } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import VText from "@magnit/core/src/components/VText/VText.vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VContinueButton from "~/containers/VContinueButton.vue";
import VEnterButton from "~/containers/VEnterButton.vue";
import VLoginModal from "~/containers/Modals/VLoginModal/VLoginModal.vue";
import { useOTPStore } from "~/store/otp";
import { useUserStore } from "~/store/user";

useHead({
  meta: [
    {
      name: "theme-color",
      content: "#f5f5f5",
    },
  ],
});

definePageMeta({
  hideNavBar: true,
  hideCookiePopup: true,
});
const { send } = AnalyticManager;
const { open: openLoginModal } = useLoginModal();
const otpStore = useOTPStore();
const userStore = useUserStore();

const swiperData = ref<SwiperClass>();
const lastSlideIndex = computed(() =>
  swiperData.value?.slides ? swiperData.value?.slides.length - 1 : 0,
);
const lastSlideDisplayed = computed(
  () => swiperData.value?.activeIndex === lastSlideIndex.value,
);
const onSwiper = (swiper: SwiperClass) => {
  swiperData.value = swiper;
};

const onLoginModalOpen = () => {
  openLoginModal();
};

onBeforeMount(() => {
  otpStore.$reset();
  userStore.$reset();
});

watch(
  () => swiperData.value?.activeIndex,
  (newIndex) => {
    switch (newIndex) {
      case 0:
        send("Onboarding:First:View");
        break;
      case 1:
        send("Onboarding:Second:View");
        break;
      case 2:
        send("Onboarding:Third:View");
    }
  },
);
</script>

<template>
  <div class="page-login">
    <div class="onboarding-container">
      <div class="onboarding-navigation">
        <div class="onboarding-pagination-wrap">
          <div class="onboarding-pagination" />
        </div>
      </div>

      <swiper
        :slides-per-view="1"
        :space-between="50"
        :pagination="{
          el: '.onboarding-pagination',
          type: 'bullets',
        }"
        :modules="[Pagination]"
        class="onboarding-slider"
        @swiper="onSwiper"
      >
        <swiper-slide class="onboarding-slide">
          <div class="onboarding-slide__info">
            <VText font="headline-large">
              Карта В1 в вашем телефоне
            </VText>
            <VText font="body-large-regular">
              Показывайте её на кассе и копите звёзды.
              Каждые&nbsp;700&nbsp;₽&nbsp;=&nbsp;1&nbsp;звезда
            </VText>
          </div>
          <img src="/images/onboarding/1.webp" alt="">
        </swiper-slide>

        <swiper-slide class="onboarding-slide">
          <div class="onboarding-slide__info">
            <VText font="headline-large">
              Меняйте звёзды на купоны
            </VText>
            <VText font="body-large-regular">
              И покупайте выгоднее
            </VText>
          </div>
          <img src="/images/onboarding/2.webp" alt="">
        </swiper-slide>

        <swiper-slide class="onboarding-slide">
          <div class="onboarding-slide__info">
            <VText font="headline-large">
              Войдите, чтобы посмотреть купоны
            </VText>
            <VText font="body-large-regular">
              Можно использовать сразу или добавить в избранное
            </VText>
          </div>
          <img src="/images/onboarding/3.webp" alt="">
        </swiper-slide>
      </swiper>

      <div class="onboarding-buttons">
        <VContinueButton
          v-if="swiperData && !lastSlideDisplayed"
          :onboard-page="swiperData.activeIndex + 1"
          class="onboarding-buttons__button"
          @click="swiperData.slideNext()"
        />
        <VEnterButton
          v-if="swiperData"
          :onboard-page="swiperData.activeIndex + 1"
          class="onboarding-buttons__button"
          :full-width="lastSlideDisplayed"
          @click="onLoginModalOpen"
        />
      </div>
    </div>

    <VLoginModal />
  </div>
</template>

<style scoped lang="postcss">
.page-login {
  width: 100%;
  height: 100%;
}

.onboarding-container {
  --swiper-pagination-color: var(--pl-system-error-accent-default);
  --swiper-pagination-bullet-inactive-color: var(--pl-icon-project-weak);
  --swiper-pagination-bullet-inactive-opacity: 1;

  position: relative;
  width: 100%;
  height: 100%;
  max-width: 559px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  .onboarding-navigation {
    padding: 20px 20px 8px;
    min-height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .onboarding-pagination {
    display: flex;
  }

  .onboarding-slider {
    width: 100%;
    height: 100%;
  }

  .onboarding-buttons {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    max-width: 559px;
    display: flex;
    align-items: baseline;
    padding-left: 20px;
    padding-right: 20px;
    z-index: var(--pl-z-index-level2);

    &__button {
      flex-basis: 100%;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  .onboarding-slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 37rem;

    &__info {
      text-align: center;
      display: flex;
      align-self: center;
      flex-direction: column;
      gap: 16px;
      max-width: 328px;
    }

    img {
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: var(--pl-z-index-negative);
      object-position: center;
      object-fit: cover;
    }
  }
}
</style>
