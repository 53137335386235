<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VYaCaptcha from "@magnit/core/src/containers/VYaCaptcha/VYaCaptcha.vue";
import type { IYaCaptchaExpose } from "@magnit/core/src/containers/VYaCaptcha/VYaCaptcha.types";
import { waitForValue } from "@magnit/core/src/utilities/async";
import VFieldControl from "~/components/VFieldControl.vue";
import InputPhone from "~/components/VInputPhone/VInputPhone.vue";
import { useOTPStore } from "~/store/otp";
import type { IInputChangeParams } from "~/components/VInputPhone/VInputPhone.types";

const INVISIBLE_CAPTCHA_ENABLED = false;

interface IState {
  phone: string;
  yacaptcha: string | null;
  valid: boolean;
}

const config = useRuntimeConfig();
const { send } = AnalyticManager;
const otpStore = useOTPStore();

const inputPhoneRef = ref<null | InstanceType<typeof InputPhone>>(null);
const captchaLoading = ref(false);
const yaCaptchaRef = ref<null | IYaCaptchaExpose>(null);

const state = reactive<IState>({
  phone: otpStore.state.phone || "",
  valid: Boolean(otpStore.state.phone),
  yacaptcha: null,
});

const isSubmitDisabled = computed(
  () =>
    !state.valid ||
    otpStore.status.code === "pending" ||
    captchaLoading.value ||
    !state.yacaptcha,
);

const formErrorText = computed<string>(
  () =>
    (otpStore.status.registerError === "captcha" && "Ошибка загрузки капчи.") ||
    "Номер телефона введён некорректно",
);

const onYaCaptchaTokenChange = (token?: string) => {
  send("Auth:PhoneNumber:Captcha:Click");
  if (!token && state.yacaptcha === token) {
    return;
  }
  state.yacaptcha = token ?? "";
};

const onPhoneChange = (params: IInputChangeParams) => {
  state.phone = params.value;
  state.valid = params.valid;
  state.yacaptcha = "";
  yaCaptchaRef.value?.reset();
};

const onSubmit = async () => {
  send("Auth:PhoneNumber:Continue:Click");

  captchaLoading.value = true;
  try {
    otpStore.status.code = "initial";
    otpStore.status.registerError = undefined;
    await yaCaptchaRef.value?.load();
    if (INVISIBLE_CAPTCHA_ENABLED) {
      yaCaptchaRef.value?.reset();
      yaCaptchaRef.value?.execute();
      await waitForValue(state.yacaptcha);
    }
  } catch {
    otpStore.status.code = "error";
    otpStore.status.registerError = "captcha";
  }
  otpStore.getCode(state.yacaptcha, state.phone);
  captchaLoading.value = false;
};

const onPhoneClick = () => {
  send("Auth:PhoneNumber:Input:Click");
};

watch(
  () => otpStore.status.registerError,
  (error) => {
    if (error === "captcha") {
      state.yacaptcha = null;
      yaCaptchaRef.value?.reset();
      yaCaptchaRef.value?.execute();
    }
  },
);

onMounted(() => {
  inputPhoneRef.value?.focus();
});
</script>

<template>
  <div>
    <VFieldControl
      :has-error="otpStore.status.code === 'error'"
      :error-text="formErrorText"
    >
      <InputPhone
        ref="inputPhoneRef"
        :value="otpStore.state.phone"
        :disabled="otpStore.status.code === 'pending'"
        :has-error="otpStore.status.code === 'error'"
        @change="onPhoneChange"
        @click="onPhoneClick"
        @keyup.enter="onSubmit"
      />
    </VFieldControl>

    <VYaCaptcha
      ref="yaCaptchaRef"
      class="phone-step__captcha"
      :invisible="INVISIBLE_CAPTCHA_ENABLED"
      :hide-shield="INVISIBLE_CAPTCHA_ENABLED"
      :no-auto-execute="INVISIBLE_CAPTCHA_ENABLED"
      :captcha-key="config.public.yandexCaptcha"
      @token-changed="onYaCaptchaTokenChange"
    />
    <VButton
      full-width
      :loading="otpStore.status.code === 'pending' || captchaLoading"
      :disabled="isSubmitDisabled"
      @click="onSubmit"
    >
      Далее
    </VButton>
  </div>
</template>

<style lang="postcss">
.phone-step__captcha {
  margin-bottom: 20px;
}
</style>
