<script setup lang="ts">
import { onMounted } from "vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";

defineProps({
  onboardPage: {
    type: Number,
    required: true,
  },
});
const emit = defineEmits(["click"]);
const { send } = AnalyticManager;

const handleClick = () => {
  emit("click");
  send("Onboarding:Enter:Click");
};
onMounted(() => {
  send("Onboarding:Enter:View");
});
</script>

<template>
  <VButton v-bind="$attrs" @click="handleClick">
    Войти
  </VButton>
</template>
